<template lang="pug">
	section#options
		h2 Onde Encontrar
		h3 Qual o tipo de informação você está buscando?
		ul.optionList
			li(v-for="item, index in options" @click="toggleOption(index)" :class="{'ativo': active !== null && active == index}").optionItem
				.icon
					img(:src="item.icon")
				p.topTitle {{item.title}}
				div(v-if="active !== null && active == index").arrow
		Alimentos(v-if="active == 0")
		Representantes(v-if="active == 1")
		Unidades(v-if="active == 2")
</template>

<script>
import Unidades from '@sections/OndeEncontrar/Unidades/Unidades'
import Representantes from '@sections/OndeEncontrar/Representantes/Representantes'
import Alimentos from '@sections/OndeEncontrar/Alimentos/Alimentos'

export default {
	name: "section-options",
	data() {
		return {
			active: null,
			options: [
				{
					icon: require('@images/paginas/onde-encontrar/icone-linha.png'),
					title: 'Linha Alimentícia',
					subTitle: 'Buscar por Pontos de Venda',
				},
				{
					icon: require('@images/paginas/onde-encontrar/icone-representantes.png'),
					title: 'Representantes Linha Alimentícia',
					subTitle: 'Buscar por Representantes',
				},
				{
					icon: require('@images/paginas/onde-encontrar/icone-unidades.png'),
					title: 'Unidades Coamo',
					subTitle: 'Buscar por Unidades',
				}
			],
		}
	},
	components: {
		Unidades,
		Representantes,
		Alimentos
	},
	methods: {
		toggleOption(index){
			if(index == this.active){
				this.active = null
			}
			else
				this.active = index
		},
	},
	mounted(){
		if (this.$route.query.active) {
			this.toggleOption(this.$route.query.active)
		}
	},
}
</script>

<style lang="stylus" scoped src="./Options.styl"></style>
